import TotalSpent from "views/user/default/components/TotalSpent";
import PieChartCard from "views/user/default/components/PieChartCard";

import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";
import Icon1 from "assets/img/landings/icon1.png";
import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "./components/CheckTable";
import ComplexTable from "./components/ComplexTable";
import DailyTraffic from "./components/DailyTraffic";

import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { useEffect, useState } from "react";

//card logo
import Card1logo from "assets/img/dashboards/main/card1.png";
import Card2logo from "assets/img/dashboards/main/card2.png";
import Card3logo from "assets/img/dashboards/main/card3.png";
import Card4logo from "assets/img/dashboards/main/card4.png";
import axios from "axios";
import stat from "./Assests/stat.png";
import tv from "./Assests/tv.png";
import wallet from "./Assests/wallet.png";
import { useNavigate } from "react-router-dom";
import Table from "./components/Table";
import { formatToINR } from "utils/utility";

const Dashboard = (props) => {
  const [investment, setInvestment] = useState([]);
  const [user, setUser] = useState({});
  const [totalinvested, setTotalInvested] = useState(0);
  const [totalprofit, setTotalProfit] = useState(0);
  const [totaldays, setTotalDays] = useState(0);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token"); // Assuming token is stored in localStorage

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    Promise.all([
      axiosInstance.get("/investment/getinvestment"),
      axiosInstance.get("/user/getuser"),
      axiosInstance.get("/investment/getprofitbook"),
    ])
      .then(([investmentResponse, userResponse, profitRes]) => {
        const investmentData = investmentResponse.data;
        const userInvestment = investmentData.filter((item) => item.uid === id);
        const userData = userResponse.data;

        userData.map((item) => {
          if (item._id === id) {
            setUser(item);

            if (!item.client) {
              navigate("/user/profile");
            }
            // calculate total days
            const date1 = new Date(item.JoinDate);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setTotalDays(diffDays);
          }
        });

        const profit = profitRes.data.filter((item) => item.uid === id);
        let totalProfit = 0;
        profit.forEach((item) => {
          totalProfit += item.profitamount;
        });
        setTotalProfit(totalProfit);

        setInvestment(userInvestment);

        let total = 0;
        userInvestment.forEach((item) => {
          total += item.investamount;
        });

        setTotalInvested(total);

        // Calculate total investment for each coin
        const coinInvestments = {};
        userInvestment.forEach((item) => {
          if (coinInvestments[item.coinname]) {
            coinInvestments[item.coinname] += item.investamount;
          } else {
            coinInvestments[item.coinname] = item.investamount;
          }
        });

        // Calculate percentages
        const totalInvestmentAmount = Object.values(coinInvestments).reduce(
          (acc, cur) => acc + cur,
          0
        );

        const percentages = {};
        Object.keys(coinInvestments).forEach((coin) => {
          percentages[coin] = (coinInvestments[coin] / total) * 100;
        });

        // Sort the coins based on their percentages in descending order
        const sortedCoins = Object.keys(percentages).sort(
          (a, b) => percentages[b] - percentages[a]
        );

        // Create an array of all the coins and their percentages and their date of investment
        const coinArray = userInvestment.map((item) => ({
          name: item.coinname,
          percentage: percentages[item.coinname],
          coinquantity: item.coinquantity,
          date: item.investdate,
        }));

        setTableData(coinArray);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + "/api/history").then((res) => {
      console.log(res.data);
      console.log("fjsd");
    });
  }, []);

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">
        <Widget
          icon={<img src={Card1logo} />}
          title={"Total Invested"}
          subtitle={"₹ " + formatToINR(totalinvested)}
        />
        <Widget
          icon={<img src={Card2logo} />}
          title={"Current Profit"}
          subtitle={"₹ " + formatToINR(totalprofit)}
        />
        <Widget
          icon={<img src={Card3logo} />}
          title={"Total Days"}
          subtitle={totaldays}
        />
        <Widget
          icon={<img src={Card4logo} />}
          title={"Total Payout Done"}
          subtitle="0"
        />
      </div>

      {/* Charts */}
      <div className="mt-5 grid ">
        <TotalSpent />
      </div>

      {/* Tables & Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <PieChartCard investment={investment} totalinvested={totalinvested} />
        <DailyTraffic />
      </div>
      <div className="mt-5 grid text-white ">
        <Table data={investment} />
      </div>
    </div>
  );
};

export default Dashboard;
