export function formatToINR(number) {
  if (isNaN(number)) return ""; // Ensure the input is a valid number

  let numStr = number.toString();
  let afterPoint = "";

  // Handle decimal part if present
  if (numStr.indexOf(".") > -1) {
    [numStr, afterPoint] = numStr.split(".");
    afterPoint = "." + afterPoint;
  }

  // Format the last three digits
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, -3);

  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }

  // Add commas every 2 digits to the remaining part
  let formatted =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

  return formatted;
}
export const formatDate = (isoDate) => {
  if (!isoDate) return "Invalid Date"; // Handle null or undefined input

  const dateObj = new Date(isoDate);

  if (isNaN(dateObj.getTime())) return "Invalid Date Format"; // Handle invalid dates

  // Convert to IST (Indian Standard Time, UTC +5:30)
  const options = { day: "2-digit", month: "short", year: "numeric" }; // Human-readable date format
  const formattedDate = dateObj.toLocaleDateString("en-IN", options);
  const formattedTime = dateObj.toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};
