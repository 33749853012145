import React, { useState, useEffect } from "react";
import axios from "axios";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";
import { GoTriangleDown } from "react-icons/go";
import { BsInfoCircle } from "react-icons/bs";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import Chart from "react-apexcharts";
import Loader2 from "components/loader2/Loader2";
import Btnleft from "components/buttonarrow/Btnleft";
import Btnright from "components/buttonarrow/Btnright";
import Loader from "components/loader/Loader";
import Pagination from "components/pagination/Pagination";
import { formatToINR } from "utils/utility";

function removeLeadingSign(num) {
  const numStr = num.toString();
  if (numStr[0] === "-" || numStr[0] === "+") {
    return parseFloat(numStr.substring(1));
  }
  return num;
}

export default function Table({ data }) {
  const [coindata, setCoindata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [inr, setInr] = useState(null); // Start with null to indicate loading

  useEffect(() => {
    const priceInInr = async () => {
      try {
        const res = await axios.get(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eur.json"
        );
        console.log(res);
        let INR = res.data.eur.inr;
        let USD = 1 / res.data.eur.usd;
        let finalINR = USD * INR;
        setInr(finalINR);
      } catch (error) {
        console.error("Error fetching currency data:", error);
      }
    };

    priceInInr(); // Call the function within useEffect
  }, []);

  // const formatterToINR = (price) => {
  //   if (inr !== null) {
  //     const convertedPrice = inr * price;
  //     return formatToINR(convertedPrice.toFixed(3));
  //   }
  //   return ""; // Fallback value while loading
  // };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  }

  if (!data.length) {
    return <div>No data to show</div>;
  }

  return (
    <div className="overflow-x-auto ">
      {!loading ? (
        <div className="flex w-full items-center justify-center py-6">
          <div className="scale-75">
            <Loader />
          </div>
        </div>
      ) : (
        <>
          <table className="!z-20 w-full overflow-hidden rounded-xl text-[13px] font-light text-white lg:text-[15px]">
            <thead className="bg-[#404040] bg-opacity-40 p-2">
              <tr>
                <th className="py-3 px-2 text-left lg:px-4">Asset</th>
                <th className="py-3 px-2 text-left lg:px-4">Coinprice (INR)</th>
                <th className="py-3 px-2 text-left lg:px-4">
                  Investamount (INR)
                </th>
                <th className="py-3 px-2 text-left lg:px-4">Coin Quantity</th>
                <th className="py-3 px-2 text-left lg:px-4">Invest Type</th>
                <th className="py-3 px-2 text-left lg:px-4">Invest Date</th>
              </tr>
            </thead>
            <tbody className="bg-[#252525] bg-opacity-40">
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="flex items-center gap-3 py-3 px-2 lg:px-4">
                    <div className="flex flex-col">
                      <span>{item.coinname}</span>
                      <span className="text-xs uppercase text-gray-500">
                        {item.coincode}
                      </span>
                    </div>
                  </td>
                  <td className="py-3 px-2 lg:px-4">
                    ₹ {formatToINR(item.coinprice)}
                  </td>
                  <td className="py-3 px-2 lg:px-4">
                    ₹{formatToINR(item.investamount)}
                  </td>
                  <td className="py-3 px-2 lg:px-4">{item.coinquantity}</td>
                  <td className="py-3 px-2 lg:px-4">{item.investtype}</td>
                  <td className="py-3 px-2 lg:px-4">
                    {formatDate(item.investdate)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
